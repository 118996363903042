exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casos-de-exito-js": () => import("./../../../src/pages/casos-de-exito.js" /* webpackChunkName: "component---src-pages-casos-de-exito-js" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-descubrimientos-js": () => import("./../../../src/pages/descubrimientos.js" /* webpackChunkName: "component---src-pages-descubrimientos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-que-hacemos-js": () => import("./../../../src/pages/que-hacemos.js" /* webpackChunkName: "component---src-pages-que-hacemos-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-study-case-js": () => import("./../../../src/templates/study-case.js" /* webpackChunkName: "component---src-templates-study-case-js" */)
}

